import { Component, OnInit, OnDestroy, ViewChild, HostListener } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";

import { NgbNav } from "@ng-bootstrap/ng-bootstrap";

import { Constants } from "../../../constants/constants";
import { RemoteAccess } from "../remote-access";
import { Tag, UserPermissions } from "../../../models/shared";
import { RemoteAccessService } from "../remote-access.service";
import { ZxEventsComponent } from "../../../components/shared/zx-events/zx-events.component";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { SharedService } from "../../../services/shared.service";
import { UsersService } from "../../account-management/users/users.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";

@Component({
    selector: "app-remote-access",
    templateUrl: "./remote-access.component.html"
})
export class RemoteAccessComponent implements OnInit, OnDestroy {
    @ViewChild(ZxEventsComponent) zxEvents: ZxEventsComponent;
    @ViewChild("remoteAccessTabset") remoteAccessTabset: NgbNav;

    remoteAccess: RemoteAccess;
    remoteAccessName: string;
    resourceTags: Tag[];
    tunnelersHost: string;

    viewOption = "accordions";
    view = "accordions";
    userPermissions: UserPermissions;

    activeTab: string;
    tabsetHasScroll: boolean;

    private remoteAccessSubscription: Subscription;
    private detailPanelViewSubscription: Subscription;

    loadingDetails = true;

    @HostListener("window:resize", [])
    private onResize() {
        this.hasScroll();
    }

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private rs: RemoteAccessService,
        private modalService: ModalService,
        private sharedService: SharedService,
        private userService: UsersService,
        private mixpanelService: MixpanelService,
        private translate: TranslateService,
        private titleService: TitleService
    ) {
        this.userService.getTunnelersHost.pipe(take(1)).subscribe(host => {
            this.tunnelersHost = host;
        });

        this.route.paramMap.subscribe(params => {
            this.remoteAccessName = params.get("name");
            if (this.remoteAccessName) this.remoteAccess = this.rs.getCachedRemoteAccess(this.remoteAccessName);
            if (!this.remoteAccess) return this.cancel();

            // Set Title
            this.titleService.setTitle("REMOTE_ACCESS", "", this.remoteAccess);

            // Loaded Details?
            if (!this.remoteAccess.hasFullDetails) this.loadingDetails = true;
            this.rs.refreshRemoteAccess(this.remoteAccessName);
        });
    }

    refresh() {
        if (this.zxEvents) this.zxEvents.refresh();
    }

    ngOnInit() {
        // Get view from local storage
        if (localStorage.getItem("detail-panel-view")) {
            this.sharedService.setDetailPanelView(localStorage.getItem("detail-panel-view"));
            this.viewOption = localStorage.getItem("detail-panel-view");
        } else {
            this.sharedService.setDetailPanelView("accordions");
            this.viewOption = "accordions";
        }

        this.remoteAccessSubscription = this.rs.remoteAccessObservable$.subscribe(remoteAccess => {
            this.remoteAccess = remoteAccess.find((r: RemoteAccess) => r.name === this.remoteAccessName);
            if (this.remoteAccess && this.remoteAccess.hasFullDetails) this.loadingDetails = false;
        });

        this.sharedService
            .getResourceTagsByType("remote_access")
            .pipe(take(1))
            .subscribe((tags: Tag[]) => {
                this.resourceTags = tags;
            });

        this.userService.userPermissions.pipe(take(1)).subscribe(perm => {
            this.userPermissions = perm;
        });

        this.detailPanelViewSubscription = this.sharedService.getDetailPanelView.subscribe(val => {
            this.view = val;
        });

        // Determine if scrollbar is needed for tabs
        window.setTimeout(() => {
            this.hasScroll();
        }, 0);
    }

    ngOnDestroy() {
        if (this.remoteAccessSubscription) this.remoteAccessSubscription.unsubscribe();
        this.detailPanelViewSubscription.unsubscribe();
    }

    hasScroll() {
        if (this.remoteAccessTabset) {
            if (
                document.getElementsByClassName("nav nav-tabs justify-content-start")[0].scrollWidth >
                document.getElementsByClassName("nav nav-tabs justify-content-start")[0].clientWidth
            ) {
                this.tabsetHasScroll = true;
            } else {
                this.tabsetHasScroll = false;
            }
        }
    }

    scrollLeft() {
        document.getElementsByClassName("nav nav-tabs justify-content-start")[0].scrollLeft = 0;
    }

    scrollRight() {
        document.getElementsByClassName("nav nav-tabs justify-content-start")[0].scrollLeft = 1000;
    }

    cancel() {
        this.gotoRemoteAccess();
    }

    gotoRemoteAccess() {
        this.router.navigate([Constants.urls.remote_access]);
    }

    async deleteRemoteAccess() {
        await this.modalService.confirm(
            "DELETE",
            "REMOTE_ACCESS",
            async () => {
                const id = this.remoteAccess.id;
                const result = await this.rs.deleteRemoteAccess(this.remoteAccess);
                if (result) {
                    this.mixpanelService.sendEvent("delete remote access", { id });
                    this.gotoRemoteAccess();
                } else {
                    return false;
                }
            },
            this.remoteAccess.name
        );
    }

    editRemoteAccess(name: string): void {
        this.router.navigate([Constants.urls.remote_access, name, "edit"]);
    }

    cloneRemoteAccess(name: string): void {
        this.router.navigate([Constants.urls.remote_access, name, "clone"]);
    }

    async toggle() {
        let action = "";
        const model = {
            is_enabled: this.remoteAccess.is_enabled
        };
        if (this.remoteAccess.is_enabled === 1) {
            action = "DISABLE";
            model.is_enabled = 0;
        } else {
            action = "ENABLE";
            model.is_enabled = 1;
        }

        await this.modalService.confirm(
            action,
            "REMOTE_ACCESS",
            async () => {
                const result = await this.rs.updateRemoteAccess(this.remoteAccess, model);
                if (result) {
                    this.mixpanelService.sendEvent(this.translate.instant(action).toLowerCase() + " remote access", {
                        id: this.remoteAccess.id
                    });
                    return true;
                } else {
                    return false;
                }
            },
            this.remoteAccess.name
        );
    }

    canEdit(remoteAccess: RemoteAccess) {
        return this.sharedService.canEditZixiObject(remoteAccess, this.resourceTags, this.userPermissions);
    }

    viewChange() {
        this.sharedService.setDetailPanelView(this.viewOption);
        localStorage.setItem("detail-panel-view", this.viewOption);
        window.setTimeout(() => {
            this.hasScroll();
        }, 0);
    }

    public refreshRemoteAccess = () => {
        this.rs.refreshRemoteAccess(this.remoteAccess.id);
    };
}
