import { Component, OnInit, OnDestroy, ViewChild, ComponentRef } from "@angular/core";
import { PercentPipe } from "@angular/common";
import { Router, ActivationEnd, RouterOutlet } from "@angular/router";
import { BehaviorSubject, Subscription, interval } from "rxjs";
import { filter, take } from "rxjs/operators";

import { StatusTextPipe } from "../../../pipes/status-text.pipe";
import { Constants } from "../../../constants/constants";
import { RemoteAccessComponent } from "../remote-access/remote-access.component";
import { RemoteAccessService } from "../remote-access.service";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { SharedService } from "../../../services/shared.service";
import { UsersService } from "../../account-management/users/users.service";
import { KeyMap, Tag, UserPermissions } from "../../../models/shared";
import { RemoteAccess } from "../remote-access";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { ZxStatusFullComponent } from "src/app/components/shared/zx-status-full/zx-status-full.component";
import { assignComponentsStatusInputsFactory } from "src/app/components/shared/zx-status-full/zx-status-full.table-adapter";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { ZxLinkTextComponent } from "src/app/components/shared/zx-link-text/zx-link-text.component";
import { ColumnFilterType } from "src/app/components/shared/filter/filter.component";

@Component({
    selector: "app-remote-access-list",
    templateUrl: "./remote-access-list.component.html",
    providers: [PercentPipe]
})
export class RemoteAccessListComponent implements OnInit, OnDestroy {
    @ViewChild(RouterOutlet) remoteAccessView: RouterOutlet;

    loading = true;
    refreshing = false;
    remoteAccess: RemoteAccess[] = [];
    remoteAccessName: string;
    selectedRows: Array<RemoteAccess> = [];

    userPermissions: UserPermissions;

    resourceTags: Tag[];
    urls = Constants.urls;

    isResizing: boolean;

    private routeSubscription: Subscription;
    private remoteAccessSubscription: Subscription;
    private remoteAccessRefreshSubscription: Subscription;

    private remoteAccessBS$ = new BehaviorSubject<RemoteAccess[]>([]);

    tableColumnsSchema: TableSchema<KeyMap<RemoteAccess>>[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            width: 280,
            visible: true,
            sticky: 1,

            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                row => row.name,
                row => row.name,
                () => true
            ),
            sortBy: row => row.name,
            textValue: row => row.name
        },
        {
            header: this.translate.instant("TAG"),
            columnDef: "access_tag",
            width: 200,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                row => row.resourceTags.map(tag => tag.name).join(", "),
                row => row.resourceTags.map(tag => tag.name).join(", "),
                row => !!row.resourceTags.length
            ),
            sortBy: row => row.resourceTags.map(tag => tag.name).join(", "),
            textValue: row => row.resourceTags.map(tag => tag.name).join(", ")
        },
        {
            header: this.translate.instant("STATUS"),
            columnDef: "status",
            width: 160,
            visible: true,

            component: ZxStatusFullComponent,
            assignComponentsInputs: assignComponentsStatusInputsFactory({ showOtherIcons: true }),
            textValue: row => this.translate.instant(this.stp.transform(row)),
            sortBy: row => this.translate.instant(this.stp.transform(row))
        },
        {
            header: this.translate.instant("IP"),
            columnDef: "ip",
            width: 140,
            visible: true,

            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                row => row.status?.source_ip?.toString(),
                row => row.status?.source_ip?.toString(),
                row => !!row?.status?.source_ip
            ),
            textValue: row => row.status?.source_ip ?? "-",
            sortBy: row => row.status?.source_ip ?? "-"
        },
        {
            header: this.translate.instant("TYPE"),
            columnDef: "type",
            width: 80,
            visible: true,

            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                row => this.translate.instant(row.type.toString().toUpperCase()),
                row => this.translate.instant(row.type.toString().toUpperCase()),
                () => true
            ),
            textValue: row => row.type,
            sortBy: row => row.type,
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("LINK"),
            columnDef: "configure_link",
            width: 60,
            visible: true,

            component: ZxLinkTextComponent,
            assignComponentsInputs: (ComponentRef: ComponentRef<ZxLinkTextComponent>, row: KeyMap<RemoteAccess>) => {
                const CompRef = ComponentRef.instance;
                CompRef.link = row.configure_link;
                CompRef.hideLink = !this.canEditRemoteAccess(row);
            }
        },
        {
            header: this.translate.instant("MUTED"),
            columnDef: "muted",
            width: 0,
            visible: false,

            textValue: row => (row.active_mute ? "muted" : ""),
            sortBy: row => (row.active_mute ? "muted" : ""),
            columnFilterType: ColumnFilterType.SELECT,
            columnSelectOptions: ["Yes", "No"],
            columnFilterValue: row => (row.active_mute ? "Yes" : "No")
        }
    ];

    constructor(
        private stp: StatusTextPipe,
        private router: Router,
        private rs: RemoteAccessService,
        private sharedService: SharedService,
        private modalService: ModalService,
        private userService: UsersService,
        private mixpanelService: MixpanelService,
        private translate: TranslateService,
        private titleService: TitleService
    ) {
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof ActivationEnd && event.snapshot.children.length === 0))
            .subscribe((event: ActivationEnd) => {
                if (event.snapshot.params && event.snapshot.params.name) {
                    this.remoteAccessName = event.snapshot.params.name;
                    this.updateSelectedRows();
                } else {
                    // Set Title
                    this.titleService.setTitle("REMOTE_ACCESS", "");
                    this.remoteAccessName = null;
                    this.selectedRows = [];
                }
            });
    }

    ngOnInit() {
        this.loading = true;

        this.userService.userPermissions.pipe(take(1)).subscribe(perm => {
            this.userPermissions = perm;
        });

        // resourceTags
        this.sharedService
            .getResourceTagsByType("remote_access")
            .pipe(take(1))
            .subscribe((tags: Tag[]) => {
                this.resourceTags = tags;
            });

        // local storage
        document.getElementById("left-container").style.flexBasis = localStorage.getItem("list-panel-width");

        this.remoteAccessSubscription = this.rs.remoteAccessObservable$.subscribe(remoteAccess => {
            this.remoteAccess = remoteAccess;
            if (this.remoteAccess) {
                this.remoteAccessBS$.next([...this.remoteAccess]);
                this.loading = false;
                this.updateSelectedRows();
            }
        });

        // Start RemoteAcess Auto Refresh
        this.startRemoteAccessRefresh();

        setTimeout(() => {
            this.updateSelectedRows();
        }, 0);
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
        this.remoteAccessSubscription.unsubscribe();
        this.stopRemoteAccessRefresh();
    }

    resizing(v: boolean) {
        this.isResizing = v;
        if (this.remoteAccessView.component) (this.remoteAccessView.component as RemoteAccessComponent).hasScroll();
    }

    canEditRemoteAccess(remoteAccess: RemoteAccess) {
        return this.sharedService.canEditZixiObject(remoteAccess, this.resourceTags, this.userPermissions);
    }

    selectRow = (remoteAccess: RemoteAccess) => {
        this.remoteAccessName = remoteAccess.name;
        this.router.navigate([Constants.urls.remote_access, remoteAccess.name]);
    };

    updateSelectedRows() {
        if (this.remoteAccessName) {
            const existingRemoteAccess = this.remoteAccess.find(user => user.name === this.remoteAccessName);
            this.selectedRows = existingRemoteAccess ? [existingRemoteAccess] : [];
        }
    }

    async refresh() {
        this.refreshing = true;
        const remoteAccessRefresh = this.rs.refreshRemoteAccessList().toPromise();
        const remoteAccessComponentRefresh =
            this.remoteAccessView && this.remoteAccessView.component
                ? (this.remoteAccessView.component as RemoteAccessComponent).refresh()
                : null;

        await Promise.all([remoteAccessRefresh, remoteAccessComponentRefresh]);
        this.refreshing = false;
    }

    async multiAction(action: string, func: (remoteAccess: RemoteAccess) => Promise<unknown>) {
        const result = await this.modalService.confirmMultiple(action, "REMOTE_ACCESS", this.selectedRows, func);
        if (!result.keepSelected && action !== "DELETE") this.selectedRows = [];
        if (result.actionTaken) {
            this.mixpanelService.sendEvent(this.translate.instant(action).toLowerCase() + " multiple remote accesses");
            if (action === "DELETE") this.selectedRows = [];
        }
    }

    multiDelete() {
        this.multiAction("DELETE", async (remoteAccess: RemoteAccess) => this.rs.deleteRemoteAccess(remoteAccess));
    }

    multiToggleState(enable: boolean) {
        this.multiAction(enable ? "ENABLE" : "DISABLE", async (remoteAccess: RemoteAccess) =>
            this.rs.updateRemoteAccess(remoteAccess, { is_enabled: enable })
        );
    }

    multiToggleMute(mute: boolean) {
        this.multiAction(mute ? "MUTE" : "UNMUTE", async (remoteAccess: RemoteAccess) =>
            this.rs.updateRemoteAccess(remoteAccess, {
                muted: mute,
                muted_until: null,
                flapping: null
            })
        );
    }

    async multiEdit() {
        await this.modalService.editMultiple(
            "REMOTE_ACCESS",
            this.selectedRows,
            async (remoteAccess: RemoteAccess, model) => {
                return this.rs.updateRemoteAccess(remoteAccess, model);
            }
        );
    }

    startRemoteAccessRefresh() {
        this.remoteAccessRefreshSubscription = interval(60000).subscribe(() => {
            this.refresh();
        });
    }

    stopRemoteAccessRefresh() {
        this.remoteAccessRefreshSubscription.unsubscribe();
    }

    get remoteAccess$() {
        return this.remoteAccessBS$.asObservable();
    }
}
